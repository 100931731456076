<template>
    <v-form v-if="dataSource" class="wrapperForm">
        <v-row dense>
            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                <v-card flat v-for="item in sortedSource" :key="item.VersionId">
                    <v-card-text>
                        
                        <div class="form-box-title title-with-right-part">
                            <span>{{ $t("Версия")}} № {{ item.OrderNumber }} {{ $t("от_время") }} {{ $moment(item.EntryDate).format("DD.MM.YYYY") }} </span>
                            
                            <div class="twrp-content">
                                <span class="result-label">{{ $t("Статус") }}:</span>
                                <StatusChip :status="item.SendStatus === 3 ? avaibleStatuses['euolSended'] : avaibleStatuses['euolWaitSend'] " />
                            </div>
                        </div>

                        <v-row class="full-width-row" no-gutters>
                            <v-col cols="12" sm="12" md="2">
                                <label class="f-label">{{$t("Автор")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="10">
                                <div class="onlyReadData more-per-lab-wrap">
                                    <v-employee-chip :id="item.AuthorEmployeeId" :name="item.Author" />
                                </div>
                            </v-col>
                        </v-row>

                        <template v-if="item.OrderNumber === 1">

                            <v-row class="full-width-row" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Действие")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="onlyReadData more-per-lab-wrap">
                                        {{ $t("Создана_карточка_документа") }}
                                    </div>
                                </v-col>
                            </v-row>

                        </template>

                        <template v-else-if="item.Changes == null || item.Changes.Data.length === 0">

                            <v-row class="full-width-row" no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{$t("Действие")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="onlyReadData more-per-lab-wrap">
                                        {{ $t("Изменения_не_найдены") }}
                                    </div>
                                </v-col>
                            </v-row>

                        </template>

                        <template v-else>

                            <v-row
                                v-for="(change, i) in item.Changes.Data"
                                :key="`version${item.OrderNumber}-change${i}`"
                                class="full-width-row"
                                no-gutters
                            >

                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{ change[0] }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="onlyReadData more-per-lab-wrap">

                                        <template v-if="change[1].startsWith('[')">
                                            <i>{{ sanitizeSystemAction(change[1]) }}</i>
                                        </template>
                                        <template v-else>
                                            <span>{{ change[1] }}</span>
                                        </template>

                                        <v-icon small>
                                            fas fa-angle-right
                                        </v-icon>
                                        
                                        <span>{{ change[2] }}</span>
                                    </div>
                                </v-col>

                            </v-row>

                        </template>

                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import { mapGetters } from 'vuex';

import StatusChip from '@/components/StatusChip'

export default {
    name: "euol-versions-tab",
    props: {
        id: {
            type: String,
            default: null
        }
    },
    components: {
        StatusChip
    },
    computed: {
        ...mapGetters('actionsource', { documentDataSource: 'getDataSource' }),
        sortedSource() {
            return [...this.dataSource]
                .sort((a, b) => {
                    return this.$moment(b.EntryDate) - this.$moment(a.EntryDate);
                });
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        }
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null
        }
    },
    methods: {
        async update() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/tabs/euolversions?id=${this.id}&cardId=${this.documentDataSource.Data.Object.Document.Card.id}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.dataSource = response?.data.payload;
        },
        sanitizeSystemAction(source) {
            let arr = Array.from(source);

            arr.splice(0, 1);
            arr.splice(source.length - 2, 1);

            return arr.join("").toLowerCase();
        }
    },
    async created() {
        await this.update();
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>